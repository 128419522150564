import React, { useEffect, useState } from "react";
import { Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Images from "./images";
import { sociallistApi } from "./API/cms.api";


function Footer() {
    const [social, SetSocial ] = useState(null);

    useEffect(()=>{
        const fetchSocial = async() => {
            const socail = await sociallistApi()
            console.log("🚀 ~ fetchSocial ~ socail:", socail)
            if(socail.status) SetSocial(socail.data);
            
        }


        fetchSocial()

    },[])


    return (

        <>
            <img src={Images.footertopimg} className="img-fluid footertopimg mt-4 w-100" width="1637px" height="31px" alt="galfi_footerlogo"/>
            <Row className="align-items-center text-center text-lg-start pb-3">
            <Col md={12} lg={3} xl={3} xxl={3} className="mt-3 mt-lg-0">
            <img src={Images.logo} className="img-fluid logos footlogo" alt="galfi_footerlogo" width="400px" height="60px" />
            </Col> 
            <Col md={12} lg={5} xl={5} xxl={4} className="mt-3 mt-lg-0">
            <p className="text-white copyright mb-0">Copyright @ 2024 Galfi. All Rights Reserved</p>
            </Col> 
            <Col md={12} lg={4} xl={4} xxl={5} className="socialmedia justify-content-center justify-content-lg-end pe-4 mt-3 mt-lg-0">
             <Link to={social?.medium} target="_blank" aria-label="socialmedium"> <img src={Images.social1} className="img-fluid mediumicon socialicon" width="70px" height="70px" alt="medium"/></Link>
             <Link to={social?.discord}  target="_blank" aria-label="socialdiscord"> <img src={Images.social2} className="img-fluid socialicon" width="70px" height="70px" alt="discord"/></Link>
             <Link to={social?.telegram}  target="_blank" aria-label="socialtelegram"> <img src={Images.social3} className="img-fluid socialicon" width="70px" height="70px" alt="telegram"/></Link>
             <Link to={social?.twitter}  target="_blank" aria-label="socialtwitter"> <img src={Images.social4} className="img-fluid twiticon socialicon" width="70px" height="70px" alt="twitter"/></Link>
            </Col> 
            </Row>
        </>

    )
}

export default Footer;