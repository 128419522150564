// import React, { useEffect, useState } from "react";
// import Carousel from "react-spring-3d-carousel";
// import { config } from "react-spring";
// import Images from "./images";
// import { LeftArrow, RightArrow } from './customarrow';

// function Carousel3D(props) {
// const {data} = props;
//   const [goToSlide, setGoToSlide] = useState(0);
//   const [offsetRadius, setOffsetRadius] = useState(2);
//   const [showNavigation, setShowNavigation] = useState(true);
//   const [animationConfig, setAnimationConfig] = useState(config.gentle);
//   const [imageOpacity, setImageOpacity] = useState(1);
//   const [animidata , setanimation ] = useState([])
//   useEffect(()=>{
// if(animidata.length == 0 && data.promoBuild){
//   const value = data?.promoBuild?.map((e , i ) =>( 
//     {
//       key: i , content : (<div className="backcard" style={{ opacity: imageOpacity,  }}>
//         <img src={e.image} alt="1" />
//         <h2 className="text-white text-center fw-600">{e.buildingName}</h2>
//       </div>)
//     }
//   ))
//   setanimation(value)
// }
//   },[data.promoBuild])


//   const slides = animidata?.map((slide, index) => ({
//     ...slide,
//     onClick: () => setGoToSlide(index)
//   }));


//   const nextSlide = () => {
//     setGoToSlide((prev) => (prev + 1) % slides.length);
//   };

//   const prevSlide = () => {
//     setGoToSlide((prev) => (prev - 1 + slides.length) % slides.length);
//   };

//   return (

//     <>

//       <div className="carousel_width my-5">
//         <Carousel
//           slides={slides}
//           goToSlide={goToSlide}
//           offsetRadius={offsetRadius}
//           showNavigation={false}
//           animationConfig={animationConfig}
//           LeftArrow={LeftArrow}
//           RightArrow={RightArrow}
          
//         />
//         <LeftArrow onClick={prevSlide} />
//         <RightArrow onClick={nextSlide} />
//       </div>

//     </>

//   );
// };

// export default Carousel3D;
import React, { useEffect, useState, useRef } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { LeftArrow, RightArrow } from './customarrow';
import { useCallback } from "react";

function Carousel3D(props) {
  const { data } = props;
  const [goToSlide, setGoToSlide] = useState(0);
  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showNavigation, setShowNavigation] = useState(true);
  const [animationConfig, setAnimationConfig] = useState(config.gentle);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [animidata, setAnimidata] = useState([]);

  const hasSetAnimidata = useRef(false);

  useEffect(() => {
    console.log("sdgasudgasgxxx" , data)
    if (!hasSetAnimidata.current && data?.promoBuild) {
    console.log("sdgasudgasgxxx calllee")

      const value = data.promoBuild.map((e, i) => (
        {
          key: i,
          content: (
            <div className="backcard" style={{ opacity: imageOpacity }}>
              <img src={e.image} alt={e.buildingName} width={"320px"} height={"100px"}/>
              <p className="text-white text-center galfi_text fw-600">{e.buildingName}</p>
            </div>
          )
        }
      ));
      setAnimidata(value);
      hasSetAnimidata.current = true;
    }
  }, [data]);

  const slides = animidata?.map((slide, index) => ({
    ...slide,
    onClick: () => setGoToSlide(index)
  }));

  const nextSlide = useCallback(() => {
    setGoToSlide((prev) => (prev + 1) % slides.length);
  });

  const prevSlide = useCallback(() => {
    setGoToSlide((prev) => (prev - 1 + slides.length) % slides.length);
  });

  return (
    <div className="carousel_width my-5">
      <Carousel
        slides={slides}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={false}
        animationConfig={animationConfig}
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
      />
      <LeftArrow onClick={prevSlide} />
      <RightArrow onClick={nextSlide} />
    </div>
  );
}

export default Carousel3D;
