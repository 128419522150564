import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Images from './images';
import { FaEye } from "react-icons/fa";
import Detailmodal from "./Components/detailmodal";
import { blogListApi } from './API/cms.api';
import { Link } from 'react-router-dom';

function Blogcarousel() {
    const [blogList, setBlogList] = useState(null);
    const [detmodal, setDetmodal] = useState(false);
    const options = {
        items: 5,
        responsiveClass: true,
        loop: false,
        margin: 30,
        slideby:1,
    autoplay: false,
        dots: false,
        nav: true,
        navText: [`<img src=${Images.rightarrowimg} width="67px" height="67px" class='leftside' alt="leftarrow" />`, `<img src=${Images.leftarrowimg} width="67px" height="67px" class='leftcaroimg' alt="rightarrow"/>`],
        // rewind: true,
        // slideBy: 4,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1.5,
            },
            991: {
                items: 2.5,
            },
            1199: {
                items: 3,
            },
            1200: {
                items: 3,
            },
            1400: {
                items: 3.5,
            },
            1600: {
                items: 3.5,
            },
            1800: {
                items: 4.5,
            }
        },
    };

    useEffect(() => {
        fetchBlog()
    },[])
const fetchBlog = async() =>{
const data = await  blogListApi()
console.log("🚀 ~ fetchBlog ~ data:", data)

if(data.status){
    setBlogList(data.data)
}
}

const datetransform = (data) => {
    let date =  new Date(data)
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  
  console.log(formattedDate); 
  return formattedDate;
    }
const [blogdata , setBlogdata] = useState(null);


    return (
        <>
         {detmodal && <Detailmodal onDismiss={() => setDetmodal(false)} data={blogdata} />}
           { blogList?.length > 0?  <OwlCarousel className='owl-theme' {...options} >
          
                { blogList && blogList?.length > 0 &&  blogList.slice(0,10).map((data,i)=>
                <div class='item'>
                    {console.log('datadata' , data)}
                    <Link to={`/blog/${data.url}`}>
                    <div className='blogcard cursor pos' onClick={() => {
                        // setDetmodal(true);
                         setBlogdata(data) ; } }>
                        <div className='blogcut'>
                            <img src={data?.image} className='img-fluid blogcardimg' alt='galfi_blog'  width="320px"
                                height="100px"/>
                        </div>
                        {/* <div className='FaEye'> */}
                            <FaEye className='FaEye'/>
                            {/* </div> */}
                        <p className='text-white fw-600 galfi_blog px-2 py-2'>{data?.heading.slice(0,48) + '...'}</p>
                        <p className='text-white px-2 mb-3'>
                            {/* <span>John Doe</span> */}
                        <span className='ms-2'>{datetransform(data?.createdAt)}</span></p>
                    </div>
                    </Link>
                </div>)}
                
            </OwlCarousel> : <><p className="no_data text-center nebula text-uppercase">No Blogs Yet</p></> }
        </>

    );
};

export default Blogcarousel;