const Images = {
    logo : require("./Assets/logo.webp"),
    astronaut : require("./Assets/astronaut.webp"),
    leftarrowimg : require("./Assets/leftarrow.webp"),
    rightarrowimg : require("./Assets/rightarrow.webp"),

    // cardimg: require("./Assets/cardimg.png"),
    // partner1 : require("./Assets/partner1.png"),
    // partner2 : require("./Assets/partner2.png"),

    youtubes : require("./Assets/Youtube.webp"),

    team1 : require("./Assets/team1.webp"),
    team2 : require("./Assets/team2.webp"),
    team3 : require("./Assets/team3.webp"),
    team4 : require("./Assets/team4.webp"),
    team5 : require("./Assets/team5.webp"),
    team6 : require("./Assets/team6.webp"),
    team7 : require("./Assets/team7.webp"),
    team8 : require("./Assets/team8.webp"),
    team9 : require("./Assets/team9.webp"),

    media1 : require("./Assets/media1.webp"),
    media2 : require("./Assets/media2.webp"),
    media3 : require("./Assets/media3.webp"),
    media4 : require("./Assets/media4.webp"),
    media5 : require("./Assets/media5.webp"),
    media6 : require("./Assets/media6.webp"),
    media7 : require("./Assets/media7.webp"),
    media8 : require("./Assets/media8.webp"),
    media9 : require("./Assets/media9.webp"),
    media10 : require("./Assets/media10.webp"),
    media11 : require("./Assets/media11.webp"),
    media12 : require("./Assets/media12.webp"),

    builton1 : require("./Assets/builton1.webp"),
    builton2 : require("./Assets/builton2.webp"),
    builton3 : require("./Assets/builton3.webp"),
    builton4 : require("./Assets/builton4.webp"),
    builton5 : require("./Assets/builton5.webp"),
    builton6 : require("./Assets/builton6.webp"),
    builton7 : require("./Assets/builton7.webp"),
    builton8 : require("./Assets/builton8.webp"),

    footertopimg : require("./Assets/footerborder.webp"),
    circle : require("./Assets/circle.webp"),
    social1 : require("./Assets/social1.webp"),
    social2 : require("./Assets/social2.webp"),
    social3 : require("./Assets/social3.webp"),
    social4 : require("./Assets/social4.webp"),
    discard:require("./Assets/discard.webp"),
    tele:require("./Assets/tele.webp"),
    twit:require("./Assets/twit.webp"),
    dot:require("./Assets/dot.webp"),
    shield : require("./Assets/shield.webp"),
    shelter : require("./Assets/shelter.webp"),
    shelterhover : require("./Assets/shelterhover.webp"),
    rocket : require("./Assets/rocket.webp"),
    rockethover : require("./Assets/rockethover.webp"),
    target : require("./Assets/target.webp"),
    targethover : require("./Assets/targethover.webp"),
    layer : require("./Assets/layer.webp"),
    layerhover : require("./Assets/layerhover.webp"),
    transact : require("./Assets/transact.webp"),
    transacthover : require("./Assets/transacthover.webp"),
    flag : require("./Assets/flag.webp"),
    flaghover : require("./Assets/flaghover.webp"),
    galaxy : require("./Assets/galaxy.webp"),
    galaxyhover : require("./Assets/galaxyhover.webp"),
    group : require("./Assets/group.webp"),
    grouphover : require("./Assets/grouphover.webp"),
    cloudChakra : require("./Assets/cloud_chakra.webp"),

   
}

export default Images
