import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Carousel } from "react-bootstrap";
import Images from "./images";
import Header from "./header";
import Footer from "./footer";
import { blogListApi } from './API/cms.api';
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from './config';

function Blog() {

    const [blogList, setBlogList] = useState(null);
    const [detmodal, setDetmodal] = useState(false);
    const [blogdata, setBlogdata] = useState(null);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    useEffect(() => {
        fetchBlog()
    }, [])

    const fetchBlog = async () => {
        const data = await blogListApi()
        console.log("🚀 ~ fetchBlog ~ data:", data)
        if (data.status) {
            setBlogList(data.data)
        }
    }

    const datetransform = (data) => {
        let date = new Date(data)
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        console.log(formattedDate);
        return formattedDate;
    }

    return (
        <>
            <div className="blog pos">
                <Header />
                <div className="innerpagetop pb-5">
                <Container className="custom_contain">
                 <h2 className="text-white nebula text-center mb-3 mb-lg-4">Blogs</h2>
                    <div className="row blogpage pb-3">
                        {blogList && blogList?.length > 0 && blogList.map((data, i) =>
                            <div className="col-md-6 col-lg-6 col-xl-4 col-xxl-4 col-xxxl-3 mt-5">
                              <Link to={`/blog/${data.url}`}>
                                <div className='blogcard cursor pos'>
                                    <div className='blogcut'>
                                        <img src={data?.image} className='img-fluid blogcardimg' alt="galfi_blogs"/>
                                    </div>
                                    <FaEye className='FaEye' />
                                    <h6 className='text-white fw-600 px-2 py-2'>{data?.heading.slice(0, 56) + '...'}</h6>
                                    <p className='text-white px-2 mb-3'>
                                        <span className='ms-2'>{datetransform(data?.createdAt)}</span></p>
                                </div>
                                </Link>
                            </div>)}
                    </div>
                    </Container>
                </div>
                <Container className="custom_contain">
                <Footer />
                </Container>
            </div>
        </>
    );
}

export default Blog;
