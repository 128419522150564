import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Carousel, Modal } from 'react-bootstrap';
import Images from "./images";
import Header from "./header";
import Footer from "./footer";
import { useLocation } from "react-router-dom";
import { BLOGDetailsApi } from "./API/cms.api";
import config from './config';

function Blogdetail(props) {

    console.log("propsprops", props)

    const location = useLocation();
    const [data, setdata] = useState(null);
    useEffect(() => {
        console.log("aeswaerwer", location);
        let pathname = location.pathname
        // setdata(props.data)
        console.log("location" ,pathname, pathname.split("/")[2])
        gettheblogData(pathname.split("/")[2])
    }, [location]);

    const dateFormater = (createdAt) => {
        const date = new Date(createdAt);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        console.log(formattedDate);
        return formattedDate
    }


    const gettheblogData = async(url)=>{
        if(url) {
            console.log("url_data", url,  "https://backend-galfi.maticz.in/v1/")
            const blogdata = await BLOGDetailsApi(url);
            console.log("resp_blogdata",blogdata, blogdata?.data);
            if(blogdata?.status == true) {
                setdata(blogdata.data)
            }
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);


    return (

        <>
            <div className="blog pos">
                <Header />
                <div className="innerpagetop pb-5">
                    <Container className="custom_contain">
                    <h1 className="text-white text-center galfi_text fw-600 mt-3 pb-4">{data?.heading}</h1>
                        <div className="path_cutz text-center mt-4 pb-3">
                            <img src={`${config.CDN}${data?.image}`} className="img-fluid detimg blogdetailimg" alt="galfi_blogs" width="100" height="100"/>
                        </div>
                        <p className="text-white mt-4">
                            {/* {data?.description}  */}
                            <div className="des_para" dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                        </p>
                        <div className="d-flex align-items-center gap-1 justify-content-end">
                            <p className="mb-0 text-white">
                                {/* John Doe  */}
                                <span className="ms-1">{dateFormater(data?.createdAt)}</span></p>
                        </div>
                    </Container>
                </div>
                <Container className="custom_contain">
                    <Footer />
                </Container>
            </div>
        </>
    )
}

export default Blogdetail;
